import React, { Component} from "react";
import ls from "local-storage";
import Sidebar from "./components/Sidebar";
import CheckoutHeader from "./components/CheckoutHeader";
import PaymentPage from "./components/PaymentPage";
import ThankyouPage from "./components/ThankyouPage";
import { Redirect, Route, Switch} from "react-router-dom";
import Checkout from "./classes/checkout";
import UpsellPage from './components/Upsell';
import env from '../src/env';
import swal from '@sweetalert/with-react';
import NotFound from './components/NotFound';
import Footer from './components/Footer';
import PaymentSuccess from './components/PaymentSuccess'
import { Helmet } from "react-helmet";
import exitIntent from './classes/ExitIntent';
import ExitIntentComponent from "./components/ExitIntent";
import AmazonPayProcess from './components/AmazonPayProcess';
import ProcessSquare from "./components/ProcessSquare";
import SidebarSkeleton from './components/SidebarSkeleton';
//Promocode text box in checkout page
import commonHooks from './classes/commonHooks';
import {NavigationHook} from './classes/NavigationHook';
import {
  isMobile
} from "react-device-detect";
import { connect } from "react-redux";
import { getLlConfig, setLlHash, getCrmConfig, setLlConfig } from './store/actions/llConfigActions';
import CheckoutComponent from './components/CheckoutComponent';
import { withRouter } from "react-router-dom";
import "./css/animate.css";
import { getCountries } from "./store/actions/countriesActions";
import './css/custom_styles/main-css.css';
import './css/custom_styles/hover-css.css';
import './css/custom_styles/one-page-css.css';

function mapDispatchToProps(dispatch) {
  return {
    callGetLlConfig: (llHash, cartInfo) => dispatch(getLlConfig(llHash, cartInfo)),
    callGetCountries: () => dispatch(getCountries()),
    callSetLlHash: (lLHash) => dispatch(setLlHash(lLHash)),
    callGetCrmConfig: (lLHash) => dispatch(getCrmConfig(lLHash)),
    callSetLLConfig: (cartInfo) => dispatch(setLlConfig(cartInfo))
  };
}

function mapStateToProps(state) {
  return {
    llConfig: state.llConfig,
    storeCountries: state.countries
  };
}
    
class App extends Component {
  constructor(props) {
    super(props);
    this.updateShipping = this.updateShipping.bind(this);
    this.updateGiftItem = this.updateGiftItem.bind(this);
    this.updateStep = this.updateStep.bind(this);
    this.showTokenUserShippingAddresses = this.showTokenUserShippingAddresses.bind(this);
    this.showTokenUserPaymentMethod = this.showTokenUserPaymentMethod.bind(this);
    this.state = initialCartStructure;
    this.state = {
      ...this.state,
      recaptchaRenderd: false,
      timerStart: new Date().getTime(),
      exitPopupVisiblity: true,
      exitPopupcounter: 0,
      cartSubTotal: 0,
      tax: false,
      pageToShow: 'customer-info',
      disablePaymentTab: true,
      updatePaymentKey: '1',
      cssObj : {
        bs : false,
        mainStyle: false,
        hoverStyle: false,
        customStyle: false,
        onepageStyle: false,
      },
      showTokenUserShipping:false,
      showTokenUserPayment:false,
      selected_token_user_details:"",
      tokenUserPhone:"",
      userTokenShowListPaymentpage:false,
      token_based_user_addresses: null,
      token_based_user_payments: null,
      token_based_user_bill_add: null,  
    }
    this.processState = true;
    this.updateCartItemQuantity = this.updateCartItemQuantity.bind(this);
    this.updateShopifyquaryParams = this.updateShopifyquaryParams.bind(this)
    this.exitIntentShow = this.exitIntentShow.bind(this)
    this.setPopException = this.setPopException.bind(this)
    this.manageCartSubTotalAmount = this.manageCartSubTotalAmount.bind(this);
    this.updateTax = this.updateTax.bind(this)
    this.manageCartSubTotalAmount = this.manageCartSubTotalAmount.bind(this);
    this.commonHooksIns = new commonHooks();
    this.removeCartItem = this.removeCartItem.bind(this);
    this.handleAccordianOnClick = this.handleAccordianOnClick.bind(this);    
    this.initiateConfig = this.initiateConfig.bind(this);
  }

 // conditional render google recaptcha script
  conditionalGoogleRecaptcha = () => {
    const script  = document.createElement("script");
    script.src    = "https://www.google.com/recaptcha/api.js";
    script.async  = true;
    document.body.appendChild(script);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps && nextProps.llConfig && nextProps.llConfig.cartData && nextProps.llConfig.cartData.checkout_enable_recaptcha && !this.state.recaptchaRenderd){
      this.conditionalGoogleRecaptcha();
      this.setState({
        ...this.state,
        recaptchaRenderd: true
      });
    }
  }

  showTokenUserShippingAddresses (showHide,detailToShow){
    this.setState({
        showTokenUserShipping : showHide,
        selected_token_user_details: detailToShow,
        tokenUserPhone: this.props.llConfig.cartData.token_based_user_addresses.phone ? this.props.llConfig.cartData.token_based_user_addresses.phone : null
      })
  }

  showTokenUserPaymentMethod (res){
    this.setState({
      showTokenUserPayment:res,
      userTokenShowListPaymentpage:res
    })
  }

  async removeCartItem(productId, rowIndex, variant_id = false, bill_id = false){
    let cartInfo = this.props.llConfig.cartData;
    let removed_items = (ls.get('removed_items')) ? ls.get('removed_items') : false;
    const params = this.parseQryStr(this.props.llConfig.llHash);
    if (params && params.slug) {
      // Shopify checkouts 
      if (Object.keys(cartInfo.shopDetails.products).length > 0) {
        Object.keys(cartInfo.shopDetails.products).forEach((key) => {
          if(cartInfo.shopDetails.products[key]._id['$oid'] === productId){
            if(key === variant_id || key === variant_id+'_'+bill_id){
              if (removed_items !== false) {
                if (removed_items.indexOf(key) === -1) {
                      removed_items.push(key);
                  } else {
                      removed_items.splice(removed_items.indexOf(key), 1);
                  }
                  ls.set("removed_items", removed_items);
              } else {
                  removed_items = [];
                  removed_items.push(key);
                  ls.set("removed_items", removed_items);
              }
            }else if(key === cartInfo.shopDetails.products[key].product_shopify_id){
              if (removed_items !== false) {
                if (removed_items.indexOf(cartInfo.shopDetails.products[key].product_shopify_id) === -1) {
                      removed_items.push(cartInfo.shopDetails.products[key].product_shopify_id);
                  } else {
                      removed_items.splice(removed_items.indexOf(cartInfo.shopDetails.products[key].product_shopify_id), 1);
                  }
                  ls.set("removed_items", removed_items);
              } else {
                  removed_items = [];
                  removed_items.push(cartInfo.shopDetails.products[key].product_shopify_id);
                  ls.set("removed_items", removed_items);
              }
            }
          }
        });
      }
      let taxData = await Checkout.calculateTax();
      this.setState({ cartInfo: cartInfo, tax : taxData });
    } else {
      // Linked based checkouts
      if (productId !== false) {
        if (Object.keys(cartInfo.shopDetails.products).length > 0) {
          Object.keys(cartInfo.shopDetails.products).forEach(async (key) => {
            if (key === productId) {
              if(removed_items !== false){
                  if(removed_items.indexOf(key) === -1){
                    removed_items.push(key);
                  }else{
                    removed_items.splice(removed_items.indexOf(key),1);
                  }
                  ls.set('removed_items', removed_items);
              }else{
                removed_items = [];
                removed_items.push(key);                
                ls.set('removed_items', removed_items);
              }
              let taxData = await Checkout.calculateTax();
              this.setState({ cartInfo: cartInfo, tax : taxData });
            }
          });
        }
      }
    }
  }

  updateTax(tax) {
    this.setState({ tax: tax });
  }

  manageCartSubTotalAmount(subTotal) {
    try {
      if (!subTotal) {
        return false;
      }
      let cartInfo = this.state && this.props.llConfig.cartData ? this.props.llConfig.cartData : false;
      let cartSubTotal = cartInfo && cartInfo.cartSubTotal ? cartInfo.cartSubTotal : 0;
      if (cartInfo !== false && cartSubTotal !== subTotal) {
        cartInfo.cartSubTotal = subTotal
        this.setState({ cartSubTotal: cartInfo });
      }

    } catch (e) {
      console.log("Error", e);
    }
  }
  exitIntentShow() {
    if(!this.props.llConfig.cartData.disable_submit)
    {
      new exitIntent({
        maxDisplays: 3,
        onExitIntent: () => {
          if (this.props.llConfig.cartData.checkout_enable_exit_popup === true) {
            if (this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_exit_popup_html !== "") {
              this.setState({ exitPopupcounter: (this.state.exitPopupcounter + 1) }, function () {
                if (this.state.exitPopupcounter === 3) {
                  return false
                } else {
                  swal({
                    content: (<ExitIntentComponent {...this.props} cartInfo={this.props.llConfig.cartData} />), buttons: false
                  })
                }
              })
            }
          }
        }
      })
    }
  }

  setPopException(visiblity) {
    this.setState({ exitPopupVisiblity: visiblity })
    this.updateCart = this.updateCart.bind(this)
  }

  async updateCartItemQuantity(stateQuantity, cartInfo, productId, shopify_id, rowIndex) {
    let removed_items = ls.get('removed_items');
    if(removed_items && removed_items.length > 0 && removed_items.indexOf(productId) > -1){
      return false;
    }
    let qty
    if (stateQuantity) {
      Object.keys(cartInfo.shopDetails.products).forEach((key) => {
        if (key === productId) {
          let max_qty = ((this.props.llConfig.cartData.shopDetails.crm_product_details && this.props.llConfig.cartData.shopDetails.crm_product_details != null && Object.keys(this.props.llConfig.cartData.shopDetails.crm_product_details).length > 0 && key in this.props.llConfig.cartData.shopDetails.crm_product_details && this.props.llConfig.cartData.shopDetails.crm_product_details[key].product_max_quantity !== undefined) ? this.props.llConfig.cartData.shopDetails.crm_product_details[key].product_max_quantity : env.DEFAULT_MAX_PRODUCT_QTY)
          //   console.log(max_qty)
          // let max_qty = env.DEFAULT_MAX_PRODUCT_QTY
          if (cartInfo.shopDetails.products[key]['quantity'] !== undefined) {
            if (max_qty === cartInfo.shopDetails.products[key]['quantity'] || (this.props.llConfig.cartData.shopDetails.crm_product_details && !(key in this.props.llConfig.cartData.shopDetails.crm_product_details))) {
              swal("You reach at max-quantity of this product.", {
                icon: "error",
              });
              cartInfo.shopDetails.products[key]['quantity'] = (parseInt(cartInfo.shopDetails.products[key]['quantity']))
            } else {
              cartInfo.shopDetails.products[key]['quantity'] = (parseInt(cartInfo.shopDetails.products[key]['quantity']) + 1)
            }
          } else {
            cartInfo.shopDetails.products[key]['quantity'] = 1
          }
          qty = cartInfo.shopDetails.products[key]['quantity']
        }
      })
    } else {
      Object.keys(cartInfo.shopDetails.products).forEach((key) => {
        if (key === productId) {
          if (cartInfo.shopDetails.products[key]['quantity'] !== undefined) {
            cartInfo.shopDetails.products[key]['quantity'] = (cartInfo.shopDetails.products[key]['quantity'] > 1) ? (parseInt(cartInfo.shopDetails.products[key]['quantity']) - 1) : parseInt(cartInfo.shopDetails.products[key]['quantity'])
          } else {
            cartInfo.shopDetails.products[key]['quantity'] = 1
          }
          qty = cartInfo.shopDetails.products[key]['quantity']
        }
      })
    }
    await this.props.callGetLlConfig(this.props.llConfig.llHash, cartInfo);
    if (this.props.llConfig.llHash.split('&').length > 1) {
      const params = this.parseQryStr(this.props.llConfig.llHash)
      const newLLHAsh = this.updateShopifyquaryParams(shopify_id, params, qty, rowIndex)
      if (newLLHAsh !== false) {
        this.props.callSetLlHash(newLLHAsh);
      }
    }
    this.setState({ cartInfo: cartInfo })
  }

  parseQryStr(queryString) {
    var params = {}, queries, temp, i, l;
    queries = queryString.split("&");
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      if (temp.length < 2) {
        params[i] = temp[i];
        continue;
      }
      params[temp[0]] = temp[1].split(',');
    }
    return params;
  }

  updateShopifyquaryParams(shopify_id, params, qty, rowIndex) {
    try {

      if (params['slug'] !== undefined || ('quantity' in params && params['quantity'])) {
        params['quantity'][rowIndex] = qty
        let newHash = ''
        Object.keys(params).forEach((key) => {
          if (isNaN(key)) {
            newHash = newHash + key + '=' + params[key] + '&';
          } else if (params[key]) {
            newHash = newHash + params[key] + '&'
          }
        })
        newHash = newHash.replace(/&([^&]*)$/, '$1')
        return newHash
      } else {
        return false;
      }

    } catch (err) {
      Checkout.manageError(err);
    }
  }

  updateCart(cartInfo) {
    this.setState({ cartInfo: cartInfo });
  }

  updateShipping(cart) {

    this.setState({ cartInfo: cart.cartInfo });
  }

  updateGiftItem(cart){
    this.setState({ cartInfo: cart.cartInfo }, function () {
      ls.set('giftItems', this.props.llConfig.cartData.giftItems);
    });
  }

  updateStep(step) {
    this.setState({ step: step }, function () {
    });
  }

  async componentWillMount() {
    try{
      let response = (this.props.llConfig.cartData) ? {'cartInfo': this.props.llConfig.cartData} : null;
      this.setState({
        cartInfo: response ? response.cartInfo : false
      });
    } catch (e) {
      console.log("Error", e);
    }
  }

  validateProductQty(products, crm_product_details) {

    if (products !== null && crm_product_details !== null) {
      Object.keys(products).forEach((key) => {
        if (key in crm_product_details && parseInt(crm_product_details[key].product_max_quantity) <= parseInt(products[key].quantity)) {
          products[key].quantity = parseInt(crm_product_details[key].product_max_quantity)
        }
        if (this.props.llConfig.llHash.split('&').length > 1) {
          const params = this.parseQryStr(this.props.llConfig.llHash)
          const newLLHAsh = this.updateShopifyquaryParams(key, params, products[key].quantity)
          if (newLLHAsh !== false) {
            this.props.callSetLlHash(newLLHAsh);
          }
        }
      })
    }
    var cart = this.props.llConfig.cartData
    cart.shopDetails.products = products
    this.setState({ cartInfo: cart })
  }

  componentDidMount(){
    if(this.props.storeCountries.countries.length <= 0)
    {
      this.props.callGetCountries();
    }
  }

  async initiateConfig(llHash){
   try{
      let storedllHash = this.props.llConfig.llHash;
      if(!this.props.llConfig.cartData || (storedllHash !== llHash) || this.props.llConfig.cartData.disable_submit)
      {
          this.props.callSetLlHash(llHash);
          this.props.callGetCrmConfig(llHash);
          await this.props.callGetLlConfig(llHash);
      }

      let response = {'cartInfo':this.props.llConfig.cartData};
      
      if (response.cartInfo) {
        this.setState({
          cartInfo: response.cartInfo,
          disablePaymentTab: ls.get('prospectId') ? false : true,
          token_based_user_login: response.cartInfo.token_based_user_login ? response.cartInfo.token_based_user_login : false,
          showTokenUserShipping: response.cartInfo.token_based_user_login && response.cartInfo.token_based_user_addresses && response.cartInfo.token_based_user_addresses.addresses && Object.keys(response.cartInfo.token_based_user_addresses.addresses).length > 0 ? true : false,
          token_based_user_addresses: response.cartInfo.token_based_user_login && response.cartInfo.token_based_user_addresses && response.cartInfo.token_based_user_addresses.addresses && Object.keys(response.cartInfo.token_based_user_addresses.addresses).length > 0 ? response.cartInfo.token_based_user_addresses.addresses : null,
        }, function () {
          let products = (response.cartInfo && response.cartInfo.shopDetails) ? response.cartInfo.shopDetails.products : null
          let crm_product_details = (response.cartInfo && response.cartInfo.shopDetails) ? response.cartInfo.shopDetails.crm_product_details : null
          this.validateProductQty(products, crm_product_details);
          ls.set('defaultShipping', response.cartInfo.shippingMethods[0][['shipping_method_crm_id']])
        });

        let tax = await Checkout.calculateTax();
        this.updateTax(tax)
        this.setPopException(response.cartInfo.checkout_enable_exit_popup);

        // Set fevicon for checkout
        if(response.cartInfo && response.cartInfo.store_fevicon !== false){
          this.commonHooksIns.setFeviconTocheckout(response.cartInfo.store_fevicon);
        }
        if(response.cartInfo.token_based_user_login){
          // get payments data
          let token_user_payments = await Checkout.getTokenUserPayments();
          this.setState({
            showTokenUserPayment: token_user_payments && token_user_payments.payments && Object.keys(token_user_payments.payments).length > 0 ? true : false,
            token_based_user_payments: token_user_payments && token_user_payments.payments ? token_user_payments.payments : null, 
            token_based_user_bill_add: token_user_payments && token_user_payments.billing_addresses ? token_user_payments.billing_addresses : null 
          })
        }
      }else{
        this.props.history.push('/404');
        this.setState({cartInfo: false});
      }

      //call card Abandan Logic 
      this.commonHooksIns.cardAbandoned();
      // this.commonHooksIns.routeHooks();

   }catch(e){
     console.log("Error",e);
   }
 }

 componentDidUpdate(prevProps, prevState, snapshot){
    try{
    }catch(e){
      console.log("Error while componentDidUpdate",e);
    }
 }
  
 async handleAccordianOnClick(event) {
  if(event === 'payment-info' && !ls.get('prospectId')){
    //if user directly clicked on payment tab without entering customer info, show error message
    swal("Enter your contact and shipping information to continue for payment!", {
      icon: "error",
    });
    this.setState({disablePaymentTab: true });
  }else{
    if(event === 'payment-info' && ls.get('prospectId')){
      this.setState({pageToShow: event, updatePaymentKey: Math.random(), disablePaymentTab: false });
    }else{
    this.setState({pageToShow: event, updatePaymentKey: Math.random()});
    }   
  }
}

  render() {
    
    let checkout_custom_css = this.props.llConfig.cartData && this.props.llConfig.cartData.shopDetails ? this.props.llConfig.cartData.shopDetails.custom_css : '';
    let checkout_custom_theme = this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_custom_theme ? this.props.llConfig.cartData.checkout_custom_theme : '';
    let custom_css = checkout_custom_theme + checkout_custom_css;
    let checkout_font = this.props.llConfig.cartData && this.props.llConfig.cartData.shopDetails ? `https://fonts.googleapis.com/css?family=${this.props.llConfig.cartData.checkout_font}` : '';
   
    return (
      
      <React.Fragment>
        {
          custom_css && <Helmet>
            <link href={checkout_font} rel='stylesheet'></link>
            <style>{custom_css}</style>
          </Helmet>
        }
        <div id="mid">
          <div >
           { (this.props.llConfig.cartData && !this.props.llConfig.cartData.disable_submit) ? <NavigationHook></NavigationHook> : ''}
            <div >
              <Switch>
              <Route exact path="/app/:llHash" render={props => (
                <CheckoutComponent
                  {...this.state}
                  initiateConfig={this.initiateConfig}
                  updateStep={this.updateStep}
                  exitIntentShow={this.exitIntentShow}
                  updateTax={this.updateTax}
                  handleAccordianOnClick={this.handleAccordianOnClick}
                  updateShipping={this.updateShipping}
                  updateGiftItem={this.updateGiftItem}
                  setPopException={this.setPopException}
                  updateCart={this.updateCart}
                  updateCartItemQuantity={this.updateCartItemQuantity}
                  manageCartSubTotalAmount={this.manageCartSubTotalAmount}
                  removeCartItem={this.removeCartItem}
                  showTokenUserShipping ={this.state.showTokenUserShipping}
                  showTokenUserShippingAddresses={this.showTokenUserShippingAddresses}
                  selected_token_user_details={this.state.selected_token_user_details}
                  tokenUserPhone={this.state.tokenUserPhone}
                  token_based_user_login={ this.state.token_based_user_login}
                  token_based_user_addresses={this.state.token_based_user_addresses}
                  exitPopupVisiblity={this.state.exitPopupVisiblity}
                  squareLoad={this.state.squareLoad}
                  exitPopupcounter={this.state.exitPopupcounter}
                  threeDSecureEnable={this.state.threeDSecureEnable}
                  showTokenUserPayment ={this.state.showTokenUserPayment}
                  showTokenUserPaymentMethod={this.showTokenUserPaymentMethod}
                  userTokenShowListPaymentpage={this.state.userTokenShowListPaymentpage}
                  token_based_user_payments={this.state.token_based_user_payments}
                  token_based_user_bill_add={this.state.token_based_user_bill_add}
                />
              )} />
              
                <Route exact path="/" render={props => (
                  <div>
                    {this.props.llConfig.llHash ? <Redirect to={"/app/"+this.props.llConfig.llHash} /> : <Redirect to="/404" />}
                  </div>
                )} />

                <Route
                  exact
                  path="/payment"
                  render={props => (
                    <div>
                      <div>
                        <div className="bg-white">
                          <div className="left-div width100"></div> {/*For mobile view*/}
                          <div className="left-div">
                            {(this.props.llConfig.cartData && !this.props.llConfig.cartData.disable_submit) &&
                            <CheckoutHeader
                              updateStep={this.updateStep}
                              cartInfo={this.props.llConfig.cartData}
                              step={this.state.step}
                              checkout_enable_single_page={false}
                            />
                            }
                            {<PaymentPage
                              {...props}
                              updateStep={this.updateStep}
                              updateShipping={this.updateShipping}
                              updateGiftItem={this.updateGiftItem}
                              cartInfo={this.props.llConfig.cartData}
                              exitIntentShow={this.exitIntentShow}
                              exitPopupVisiblity={this.state.exitPopupVisiblity}
                              setPopException={this.setPopException}
                              squareLoad={this.state.squareLoad}
                              exitPopupcounter={this.state.exitPopupcounter}
                              updateTax={this.updateTax}
                              threeDSecureEnable={this.state.threeDSecureEnable}
                              initiateConfig={this.initiateConfig}
                              checkout_enable_single_page={false}
                              updateCart={this.updateCart}
                              isMobile={isMobile}
                              showTokenUserPayment ={this.state.showTokenUserPayment}
                              showTokenUserPaymentMethod={this.showTokenUserPaymentMethod}
                              userTokenShowListPaymentpage={this.state.userTokenShowListPaymentpage}
                              token_based_user_addresses={this.state.token_based_user_addresses}
                              token_based_user_payments={this.state.token_based_user_payments}
                              token_based_user_bill_add={this.state.token_based_user_bill_add}       
                              token_based_user_login={ this.state.token_based_user_login}
                           />}
                          </div>
                        </div>
                        <div className="bg-gray">
                          {
                            (this.props.llConfig.cartData && !this.props.llConfig.cartData.disable_submit) ? <Sidebar isMobile={isMobile} {...props} updateStep={this.updateStep} updateTax={this.updateTax} tax={this.state.tax} updateCart={this.updateCart} updateCartItemQuantity={this.updateCartItemQuantity} cartInfo={this.props.llConfig.cartData} manageCartSubTotalAmount={this.manageCartSubTotalAmount} removeCartItem={this.removeCartItem}/>
                            : <SidebarSkeleton />
                          }
                        </div>
                        <Footer timerStart={this.state.timerStart} />
                      </div>
                    </div>
                  )}
                />
                <Route exact path="/thank-you" render={

                  props => (
                    <div>
                      {this.props.llConfig.cartData ? <div>
                        <div className={`bg-white  ${this.props.llConfig.cartData.checkout_enable_single_page ? 'cstm-width' : '' }`}>
                          <div className="left-div width100"></div>
                          <div className={`left-div ${this.props.llConfig.cartData.checkout_enable_single_page ? 'one-page-checkout' :'notonepage'}`}>
                            {<CheckoutHeader
                              updateStep={this.updateStep}
                              cartInfo={this.props.llConfig.cartData}
                              checkout_enable_single_page={this.props.llConfig.cartData.checkout_enable_single_page ? true : false }
                              step={!this.props.llConfig.cartData.checkout_enable_single_page ? this.state.step : null}
                              fullPath={!this.props.llConfig.cartData.checkout_enable_single_page ? true : false}
                              {...props}
                              breadcrumbs={ !this.props.llConfig.cartData.checkout_enable_single_page ? [
                                {
                                  key: "info",
                                  label: "Customer Information",
                                  link: "/",
                                  className: "breadcrumb-item "
                                },
                                {
                                  key: "pay",
                                  label: "Shipping & Payment",
                                  link: "/payment",
                                  className: "breadcrumb-item "
                                },
                                {
                                  key: "thank",
                                  label: "Thank You",
                                  link: "/thank-you",
                                  className: "breadcrumb-item "
                                }
                              ] : null}
                            />}
                            <ThankyouPage
                             {...props} 
                             checkout_enable_single_page={this.props.llConfig.cartData.checkout_enable_single_page ? true : false }
                             cartInfo={this.props.llConfig.cartData}
                             initiateConfig={this.initiateConfig}
                             setPopException={this.setPopException} 
                             updateStep={this.updateStep} />
                          </div>
                        </div>
                        <div className={`bg-gray  ${this.props.llConfig.cartData.checkout_enable_single_page ? 'background-white' : '' }`}>
                            {<Sidebar isMobile={isMobile} {...props} updateStep={this.updateStep} updateTax={this.updateTax} tax={this.state.tax} updateCart={this.updateCart} updateCartItemQuantity={this.updateCartItemQuantity} cartInfo={this.props.llConfig.cartData} manageCartSubTotalAmount={this.manageCartSubTotalAmount} removeCartItem={this.removeCartItem}
                            checkout_enable_single_page={this.props.llConfig.cartData.checkout_enable_single_page ? true : false }
                            />}
                        </div>
                        <Footer timerStart={this.state.timerStart} />
                      </div> : <Redirect to="/" />}
                    </div>
                  )}
                />

                <Route exact path="/upsell" render={
                  props => (
                    <div>
                      <div className="upsell-page">

                        <UpsellPage setPopException={this.setPopException} initiateConfig={this.initiateConfig} cartInfo={this.props.llConfig.cartData}
                          step={this.state.step} {...props} timerStart={this.state.timerStart} cssObj={this.state.cssObj}/>
                      </div>
                    </div>

                  )} />

                <Route exact path="/paypal-success" render={
                  props => (
                    <div>

                      <PaymentSuccess cartInfo={this.props.llConfig.cartData}
                        step={this.state.step} {...props} />
                    </div>

                  )} />

                <Route
                  exact
                  path="/process-square"
                  render={props => (
                    <div>
                      <ProcessSquare {...props} countries={this.props.storeCountries.countries} initiateConfig={this.initiateConfig} updateStep={this.updateStep} cartInfo={this.props.llConfig.cartData} />


                    </div>

                  )}
                />

               

                <Route exact path="/404" render={props => (
                  <div className="not-found-page">
                    <NotFound {...props} setPopException={this.setPopException} updateCart={this.updateCart} cartInfo={this.props.llConfig.cartData} />
                    <Footer timerStart={this.state.timerStart} customStyle={{ "background": "none" }} />
                  </div>
                )} />

                <Route path='/amazon-pay' exact={true} render={props => (
                  <div className="not-found-page">
                    <AmazonPayProcess openPaymentOption={true} {...this.props} />
                  </div>
                )} />

                <Route path='*' exact={true} render={props => (
                  <div className="not-found-page">
                    <NotFound {...props} setPopException={this.setPopException} />
                    <Footer timerStart={this.state.timerStart} />
                  </div>
                )} />
              </Switch>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const initialCartStructure = {
  cartInfo: {
    shopDetails: {},
    cart: {
      currency: "",
      totalItems: 0,
      totalAmount: "",
      category: "",
      quantity: 1,
      products: [],
      shipping: {}
    },
    shippingMethods: [],
    countries: []
  },
  step: "info",
  countries :  [],
  squareLoad:false,
  tax : false,
  threeDSecureEnable:false,
  cartSubTotal:0
};

const ConnectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default withRouter(ConnectedApp);